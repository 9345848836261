import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"

export default function NewPlayersWantedU12() {
  return ( 
    <Layout>
        <section className="container">
        <h1>New Players Wanted U13s</h1>
        <InnerPageTwitterLayout>
            <img src="boys-u13-juniors.jpg" className="img-fluid mb-4 team-page-hero" />
            <p className="lead">Our current under 13’s (YR 8) are looking for new PLAYERS.</p>
            <p>We have 3 teams that play in the Garforth League Division 2. 3 and 6 on a Sunday morning and train every Saturday Morning. Please 
contact us at <a href="mailto:leedscityjnrs@gmail.com">leedscityjnrs@gmail.com</a>.</p>
            <p>For more information about the teams and when/where they play see the team specific pages <Link to="/team-boys/u13-juniors">U12 Juniors</Link>, <Link to="/team-boys/u13-blues">U12 Blues</Link> and <Link to="/team-boys/u13-colts">U12 Colts</Link>.</p>
        </InnerPageTwitterLayout>
      </section>
    </Layout>
  );
}
